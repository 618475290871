import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "field-group",
  "data-group": "1"
}
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { for: "company-name" }
const _hoisted_4 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("tender_details.company")), 1),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "field__text",
        id: "company-name",
        placeholder: _ctx.$t('search.customer_placeholder'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.company) = $event)),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('applyFilters')), ["enter"]))
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.company]
      ])
    ])
  ]))
}