import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AuthModal = _resolveComponent("AuthModal")!
  const _component_PopupNotification = _resolveComponent("PopupNotification")!
  const _component_MobileFiltersModal = _resolveComponent("MobileFiltersModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_AuthModal),
    _createVNode(_component_PopupNotification),
    _createVNode(_component_MobileFiltersModal)
  ], 64))
}