import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "field-group",
  "data-group": "1"
}
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { for: "source" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSelect = _resolveComponent("VueSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("tender_page.source")), 1),
      _createVNode(_component_VueSelect, {
        options: _ctx.sources,
        modelValue: _ctx.source,
        "onUpdate:modelValue": _ctx.setSource,
        placeholder: _ctx.$t('search.source_placeholder'),
        searchable: false,
        components: {
          Deselect: _ctx.dropdownClearInputButton,
          OpenIndicator: _ctx.dropdownToggleButton,
        }
      }, null, 8, ["options", "modelValue", "onUpdate:modelValue", "placeholder", "components"])
    ])
  ]))
}