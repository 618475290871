import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3044e33c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'header__search': !_ctx.isSecondary,
      'header-secondary__search': _ctx.isSecondary,
    })
  }, [
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.searchTender && _ctx.searchTender(...args)), ["prevent"]))
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "header-search",
        placeholder: _ctx.$t('search.placeholder'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event))
      }, null, 8, _hoisted_1), [
        [_vModelText, _ctx.query]
      ]),
      _createElementVNode("input", {
        type: "submit",
        value: _ctx.$t('search.btn'),
        class: "btn btn-blue"
      }, null, 8, _hoisted_2)
    ], 32)
  ], 2))
}